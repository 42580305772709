.image-slider {
    max-width: 100%;
    overflow: hidden;
  }
  
  .image-slider img {
    width: 100%;
    height: auto;
    display: none;
  }
  
  .image-slider img.active {
    display: block;
  }
  
  #slideshow-container {
    position: relative;
    padding: 0;
  }
  
  .button-div {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }