.App
{
  text-align: center;
}
.head {
  font-family: monospace;
}

.col
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}


.img
{
  display: block;
  margin-right: auto;
  margin-top: 4%;
}

.card
{
  box-shadow: 3px 3px 3px; 
}

.title-small
{
  font-family:monospace;
  color: #57626c;
  font-size: medium;
  text-align: left;  

}

.title-black
{
  font-family:monospace;
  color: #000000;
  font-size: medium;
  text-align: left;  

}

.title-large
{
  font-family:monospace;
  text-align: left; 
  font-size: 20px;
  border-bottom: 1px solid #57626c;
}

.title-para
{
  font-family: 'Roboto', sans-serif;
  justify-content: space-between!important;
  font-size: small;
  text-align: left;
  padding: 2%;
}

.button-div
{
  margin-top: 100px;
  margin-bottom: 100px;
  
}

.button
{
  background-color: #212529;
  font-family: 'Exo', sans-serif;
  height:60px;
  width: 90%;
  font-size: large;
  box-shadow: 1px 1px 5px black;
}

.button:hover {background-color:#000000}

.s-button
{
  background-color: #212529;
  border-color: #000000;
  font-family: 'Exo', sans-serif;
  font-size:small;
  box-shadow: 3px 3px 3px;
  border-radius: 15px;
      width: 265.6px;
    height: 38.6px;
}

.s-button:hover {background-color:#000000}

.s-button:active
{
  background-color:#000000
}

.form-face
{
  margin: 10%;
  font-family: 'Roboto', sans-serif;
  padding: 9%;
  box-shadow: 0 0 0 0px inset,0 0 3px;
  display: inline-block;  
  margin-left: 10px;
  margin-right: 10px;
 
}

.title
{
  color:#000000;
  font-size:xx-large;
  font-family: 'Exo', sans-serif;
}

.header
{
  width:100%; height:1px; background: #212529;
}

.label
{
  font-family: 'Exo', sans-serif;
  margin-bottom: 30px;
  font-size:medium;
  width: 50;
}

.label-select
{
  font-family: 'Exo', sans-serif;
  text-align:left;
  border-radius: 5px;
}

.label-note
{
  font-family: 'Exo', sans-serif;
  height: 80px;
  margin-bottom: 30px;
}

.label-option
{
  width: 50px;
}
.sch-button
{
  padding-left: 28%;
  padding-right: 28%;
  padding-top: 3%;
  padding-bottom: 3%;
  box-shadow: 2px 2px 5px black;
  border-radius: 10px;
  background-color: #212529;
  color: white;
  font-family: 'Exo', sans-serif;  
}

.sch-button:hover 
{
  background-color:#000000
}

.form-starter
{
  position: inherit;
}

.footer__copyright {
  margin-bottom: 1rem;
  color: var(--color-bg);
}

.Support
{
  font-family: "Montserrat",sans-serif;
  padding: 6%;
  text-align: center;
  
}

.Support-call
{
  font-family: "Montserrat",sans-serif;
  padding: 6%;
  padding-bottom: 5%;

}

.Support-mail
{
  font-family: "Montserrat",sans-serif;
  padding: 6%;
  padding-bottom: 5%;
  border-bottom: 1px solid black;
}

.add
{
  box-shadow: 0 0 0 0px inset,0 0 3px;
  padding: 8%;
  
}

.title-add
{
  font-family:monospace;
  color: #57626c;
  font-size: medium;
  text-align: center;  
  padding-top: 5%;

}

.call
{
  color: black;
  font-family: 'Exo', sans-serif;
}

.label-date
{
  font-family: 'Exo', sans-serif;
}

.map
{
  position: inherit;
}

.result
{
  text-align: center;
  margin: 10%;
  margin-top: 20%;  
}

.title-result
{
  font-family: 'Exo', sans-serif;
}

.heading{
  color: black;
  background-color: rgb(0, 0, 0);
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 200  ;
}




.ruppeesign {
  font-family: monospace;
  color: red;
  text-align: right; 
  vertical-align: top;
  font-size: 0.75em;
  margin-left: 0.7em;
}


.title-price
{
  font-family:monospace;
  font-size: larger;
  text-align: left; 
}

.shop-visit-button {
  width: 100%;
  height: 70px;
  font-size: 22px; 
  font-family: 'Exo', sans-serif;
  background-color: #212529;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  text-decoration: none;
}

.shop-visit-button:hover {
  background-color: #000000;
}